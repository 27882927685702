.App {
  text-align: center;
  background-image: url("./img/background.png");
  background-size: 2000px;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(80%);
  padding-bottom: 10%;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(51, 51, 51);
}

html {
  height: 100%;
}
html > body {
  height: 100%;
}
html > body > #root {
  height: 100%;
}
.autocomplete-header {
  position: sticky;
}